import '@babel/polyfill';

import { webData } from './misc/analytics';
import { domain } from './misc/auction_genius';

// To generate:
// curl "https://mui.aws.manheim.com/combo?0.28.0/man-customer/css/base.css&0.28.0/man-customer/css/icons.css&0.18.0/man-customer/css/headers.css&0.28.0/man-customer/css/buttons.css&0.28.0/man-customer/css/tables.css&0.28.0/man-customer/css/forms.css&0.28.0/man-customer/css/tabs.css&0.28.0/man-customer/css/messages.css&0.28.0/man-customer/css/helpers.css&0.28.0/man-customer/css/padding-margin.css&0.28.0/man-customer/css/refinements.css" > public/mui-subset.css
// And for the sandboxed version (for external components):
// curl "https://mui.aws.manheim.com/combo?0.28.0/man-customer/css/base-sandboxed.css&0.28.0/man-customer/css/icons-sandboxed.css&0.18.0/man-customer/css/headers-sandboxed.css&0.28.0/man-customer/css/buttons-sandboxed.css&0.28.0/man-customer/css/tables-sandboxed.css&0.28.0/man-customer/css/forms-sandboxed.css&0.28.0/man-customer/css/tabs-sandboxed.css&0.28.0/man-customer/css/messages-sandboxed.css&0.28.0/man-customer/css/helpers-sandboxed.css&0.28.0/man-customer/css/padding-margin-sandboxed.css&0.28.0/man-customer/css/refinements-sandboxed.css" > public/mui-subset-sandboxed.css
// Resulted files were reduced in this commit due to extremely big base64 encoded 'Lato' fonts
// Fonts were extracted into separate files
// Use git blame to determine commit hash
import 'PUBLIC/mui-subset.css';
import 'PUBLIC/grid.css';
import 'PUBLIC/print.css';

window.webData = webData;
document.domain = domain;
