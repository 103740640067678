import getCookie from 'SRC/util/cookie';

function baseDomainString(domain) {
  const parsedDomain = domain.split(/\./);

  if (parsedDomain.length > 1) {
    return [
      parsedDomain[parsedDomain.length - 2],
      parsedDomain[parsedDomain.length - 1]
    ].join('.');
  }

  return domain;
}

// Only mess with the domain for AuctionGenius users
const domain = getCookie('ag_user')
  ? baseDomainString(document.domain)
  : document.domain;

export { domain };
